import React, { useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import '../CustomModal.css';
import '../CardMyCart.css';
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import {
    orderStatus
} from "../library"

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function OrderRefund(props) {
    const [modal_order, setModal_order] = useState(false);
    const [order_list, setOrder_list] = useState([]);
    const [order_view, setOrder_view] = useState([]);
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        api_refund_pay(message)
    }

    useEffect(() => {
        api_getRefundList()
    }, []);

    async function api_getRefundList() {
        setmodal_load({ enable: true, message: "Loading refund pending list." })
        await sleep(500)

        const formData = new FormData();
        formData.append('mode', "refund")

        axios.post(props.app_hostname + "/api/order/admin/common/getorderlistbyfilter", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setOrder_list(response.data.order)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_getorderview(order) {
        setmodal_load({ enable: true, message: "Loading order data." })
        await sleep(500)

        setOrder_view([])

        const formData = new FormData();
        formData.append('order_id', order.order_id)

        axios.post(props.app_hostname + "/api/order/admin/common/getorderbyid", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setOrder_view(response.data.order)
                    setModal_order(true)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_order(false)
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_refund_pay(order) {
        setmodal_load({ enable: true, message: "Refund updating." })
        await sleep(500)

        const formData = new FormData();
        formData.append('order_id', order.order_id)
        formData.append('payment_transaction', document.getElementById('payment_transaction').value)
        formData.append('buyer_shopid', order.buyer_shopid)

        axios.post(props.app_hostname + "/api/order/admin/refund/payrefund", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                setModal_order(false)
                if (response.data.status === 'OK') {

                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        setOrder_list(order_list.filter(function (item) {
                            return item.order_id !== response.data.order_id
                        }))
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_order(false)
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }



    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="fas fa-hand-holding-usd color-orange"></i> Buyer Refund Pending</h4>
                            <p className="mb-3">Manage all refund pending</p>
                            <hr className="new-section-xs mb-2" />
                            <div className="d-flex justify-content-start gap-3 mt-2">
                                <button className="btn btn-success btn-sm" onClick={() => { api_getRefundList() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-1 mb-md-4">
                        <h5>Refund Pending List <span>({order_list.length} Orders)</span></h5>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered" >
                                <thead>
                                    <tr className="admintable-header">
                                        <th className="id-fixwidth">#</th>
                                        <th>Order ID</th>
                                        <th>Order Date</th>
                                        <th>Buyer Name</th>
                                        <th>Seller Name</th>
                                        <th>Sub Total</th>
                                        <th>Shipping</th>
                                        <th>Total</th>
                                        <th className="text-center action-fixwidth">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        order_list.map((item, index) => (
                                            <tr key={item.payment_id}>
                                                <td><i className="far fa-newspaper rows-icon "></i> {index + 1}</td>
                                                <td className="font-bold my-0 py-0">{item.order_id}</td>
                                                <td><p className="my-0 py-0">{moment(new Date(item.createtime)).subtract(7, 'hours').format('YYYY-MM-DD HH:mm:ss')}</p></td>
                                                <td className="font-bold my-0 py-0">
                                                    {item.buyer_shopname}
                                                    <p className="my-0 py-0">{item.buyer_shopprovince}</p>
                                                </td>
                                                <td className="font-bold my-0 py-0">
                                                    {item.seller_shopname}
                                                    <p className="my-0 py-0">{item.seller_shopprovince}</p>
                                                </td>
                                                <td>{Number(item.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} </td>
                                                <td>{Number(item.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                                <td>{Number(item.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                                <td>
                                                    <Link className="btn btn-icon btn-sm btn-success btn-hover btn-bordered me-1" title="Edit" onClick={() => { api_getorderview(item) }}><i className="fas fa-pen"></i></Link>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <div className="modal-payment" style={{ display: modal_order ? "block" : "none" }}>
                <div className='modal-payment-wrapper'>
                    {
                        order_view.length > 0 ?
                            order_view.map((order, index0) => (
                                <div className="col-md-12 mb-md-2 mb-lg-0" key={order.order_id}>
                                    <div className="card-mycart mb-2">
                                        <div className="card-mycart-header py-1">
                                            <div className="d-block d-md-flex justify-content-between py-0 flex-wrap">
                                                <div className="d-block">
                                                    <div className="card-mycart-title"><i className="font-size-14 fas fa-user-tag text-info"></i> ผู้ขาย : {order.seller_shopname}</div>
                                                    <p className="card-mycart-subtitle">จังหวัด {order.seller_shopprovince}</p>
                                                </div>
                                                <div className="d-block">
                                                    <div className="text-left text-md-right">{order.order_id}</div>
                                                    {orderStatus(order, "buyer")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-mycart-body">
                                            <div className="d-block d-md-flex justify-content-between pt-0 pb-2 flex-wrap border-bottom">
                                                <div className="d-block">
                                                    {
                                                        order.orderitem !== null ?
                                                            order.orderitem.map((orderitems, index1) => (
                                                                <div className={"d-block d-md-flex py-1 " + (index1 > 0 ? "border-top" : "")} key={orderitems.orderitem_id}>
                                                                    <div className="d-flex">
                                                                        <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + `${orderitems.productitem_id}/` + `${orderitems.productitem_id}` + "-1-" + orderitems.img1_key + ".jpg"} alt="Image Description" /></div>
                                                                        <div className='d-block product-detail'>
                                                                            <div className="mycart-box-productname py-0">{orderitems.productitem_name}</div>
                                                                            <div className="mycart-box-productinter py-0">{orderitems.productitem_inter}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mycart-box-pricelist">
                                                                        <table className='payment-table'>
                                                                            <tbody className="d-block mt-0">
                                                                                {
                                                                                    orderitems.orderitem_pricelist !== null ?
                                                                                        orderitems.orderitem_pricelist.map((pricelist, index1) => (
                                                                                            <tr className="mycart-table-pricelist-row py-0" key={pricelist.pricelist_id} style={{ paddingTop: "0px" }}>
                                                                                                <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                                <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                                <td className="product-quantity">x {pricelist.select}</td>
                                                                                                <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="d-block mt-2 mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                                    <div className="d-flex justify-content-between py-0 mt-2">
                                                        <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                        <div className="table-summary-cellt">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-0">
                                                        <div className="table-summary-cell">ค่าขนส่ง</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-0 border-bottom">
                                                        <div className="table-summary-cell">ยอดรวม</div>
                                                        <div className="table-summary-cell aligh-right">฿ {Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                    </div>
                                                    {
                                                        order.order_status === "PC_RTN_RCV" ?
                                                            <>
                                                                <div className="d-flex justify-content-between py-0 mt-2 text-danger">
                                                                    <div className="table-summary-cell">ยอดที่ต้องคืนผู้ซื้อ</div>
                                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.return_shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                </div>
                                                                <div className="d-flex justify-content-between py-0 text-danger">
                                                                    <div className="table-summary-cell">ยอดที่ต้องจ่ายผู้ขาย</div>
                                                                    <div className="table-summary-cell aligh-right">฿ {Number(order.return_shop_income).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="d-flex justify-content-between py-0 mt-2 text-danger">
                                                                <div className="table-summary-cell">ยอดที่ต้องคืนผู้ซื้อ</div>
                                                                <div className="table-summary-cell aligh-right">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>

                                            <table className='payment-table'>
                                                <tbody className="d-block mt-0">
                                                    <tr className="">
                                                        <td width={"160px"} className="mt-0 mb-0">ผู้ซื้อ</td>
                                                        <td className="mt-0 mb-0">{order.shipping_recievename}</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="mt-0 mb-0">เบอร์โทรศัพย์</td>
                                                        <td className="mt-0 mb-0">{order.shipping_telno}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {
                                                order.order_status === "PC_RTN_RCV" ?
                                                    <>
                                                        <div className="d-block  mt-2">
                                                            <div className="editname pr-5 text-danger">เหตุผลในการขอคืนสินค้า</div>
                                                            <div className="editname pr-5">{order.return_reason}</div>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                            <div className="d-block pt-0 pb-2 border-bottom">
                                                <div className="d-block d-xl-flex mt-2">
                                                    <div className="form-group py-0 mt-0 mb-0" style={{ minWidth: "300px" }}>
                                                        <label className="text-warning pr-5">กรอกเลขธุรกรรมคืนเงิน</label>
                                                        <input className="form-control" type="text" name="payment_transaction" id="payment_transaction" placeholder='กรอกเลขธุรกรรมคืนเงิน' required />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="d-flex justify-content-between gap-2 mt-4 ">
                                                <button type="button" className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                        setModal_popup({
                                                            modaltype: 'modal-confirm',
                                                            headers: 'Confirmation',
                                                            message: "Please click on confirm button to continue this operation.",
                                                            enable: true,
                                                            confirmcode: order,
                                                        });
                                                    }}>คืนเงินแล้ว</button>
                                                <button type="button" className="btn btn-sm btn-light"
                                                    onClick={() => {
                                                        setModal_order(false)
                                                        setOrder_view([])
                                                    }}>ปิดหน้าต่าง</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))
                            :
                            null
                    }
                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modal_order ?
                    <div className="meya-backdrop"></div>
                    :
                    null
            }
        </div>
    )
}

export default OrderRefund